import groupPayoutsModule from '../groupPayoutsModule';

function configureGroupPayoutsController($window) {
  'ngInject';

  const ctrl = this;

  const init = function () {
    window.location.href = `https://create.roblox.com/dashboard/group/payouts?groupId=${ctrl.groupId}`;
  };

  ctrl.$onInit = init;
}

groupPayoutsModule.controller('configureGroupPayoutsController', configureGroupPayoutsController);
export default configureGroupPayoutsController;
